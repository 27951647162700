.app{
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.background__image{
  position:fixed;
  top:0;
  left:0;
  min-height: 100vh;
  height: 100%;
  z-index: 0;
  background:  no-repeat center center fixed;
  
}


.main-container {
  padding-top:90px;
  padding-bottom:90px;
  background-color: #FFFFFF;
  overflow-y: scroll;
  min-height:100vh;
}

::-webkit-scrollbar {
  display: none;
}